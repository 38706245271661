import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  serialize,
  useGetProposalDetailsQuery,
  usePaymentQuery,
  useUpdateOrderMutation,
  useProposalFinalSubmitMutation,
} from "../../services/ProposalServices";
import EditIcon from "@mui/icons-material/Edit";
import PlanCard from "../Proposal/components/PlanCard";
import { StyledButton } from "../../components/Button/Button";
import Bike from "../../components/Bike/Bike";
import { useNavigate } from "react-router-dom";

// Styling object
const paperStyle = {
  background: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 6%) 0px 4px 12px",
  maxWidth: "608px",
  mx: "auto",
  p: 2,
  mb: 2,
};

// Data Display Component
const DataComp: React.FC<{ title: string; value?: string }> = ({
  title,
  value,
}) => {
  if (!value) return null;

  return (
    <Grid item md={6} xs={12} sm={12}>
      <Typography
        variant="h6"
        sx={{
          fontSize: "18px",
          color: "var(--dark-grey-1)",
          marginBottom: "12px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          whiteSpace: "pre-line",
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
};

const ProposalSummary = () => {
  const { isProposalLoading, proposal_data, product_data, quote_data } =
    useGetProposalDetailsQuery();

  const isOD = product_data?.POLICY_TYPE === "OD";
  const FORMDATA = proposal_data?.FORMDATA;

  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const { paymentData, isPaymentLoading } = usePaymentQuery();
  const { submitProposal, isSubmitting, data } =
    useProposalFinalSubmitMutation();
  const { updateOrder } = useUpdateOrderMutation();

  const handlePayment = (paymentData: any) => {
    const { PG_FORM } = paymentData;
    const { fields, method, url } = PG_FORM;

    updateOrder();

    if (method === "POST") {
      const form = document.createElement("form");
      form.method = method;
      form.action = url;

      Object.keys(fields).forEach((key) => {
        const input = document.createElement("input");
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      const query = fields ? serialize(fields) : "";
      window.open(query ? `${url}?${query}` : url, "_self");
    }
  };

  useEffect(() => {
    if (!isSubmitting && data) {
      handlePayment(data);
    }
  }, [isSubmitting, data]);

  if (isProposalLoading || !FORMDATA) return null;

  const handleEditClick = () => {
    if (proposal_data?._id) {
      navigate(`/proposal?stage=PF&proposal_id=${proposal_data._id}`);
    }
  };

  const {
    company_name,
    owner_type,
    first_name,
    last_name,
    email,
    mobile_no,
    gender,
    is_married,
    dob,
    pan_no,
    occupation_val,
    nominee_name,
    nominee_age,
    nominee_relation,
    address,
    pincode,
    city_name,
    state_name,
    registration_number,
    engine_number,
    chassis_number,
    is_financed,
    prev_insurer_name,
    prev_policy_no,
    financier_name,
    reg_address,
    reg_city_name,
    reg_pincode,
    reg_state_name,
    tp_prev_insurer_name,
    tp_prev_policy_no,
    tp_existing_exp_date,
  } = FORMDATA;

  return (
    <>
      {isSubmitting && <Bike message="Resubmitting Proposal" />}
      <Grid container>
        <Grid item md={3} sm={12} xs={12}>
          <PlanCard />
        </Grid>
        <Grid item md={9} sm={12} xs={12}>
          <Paper sx={paperStyle}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb="40px"
            >
              <Typography
                variant="h6"
                fontSize="22px"
                fontWeight={600}
                sx={{ flex: 1, textAlign: "center" }}
              >
                Important Details to review before payment
              </Typography>
              <Tooltip title="Edit Details">
                <IconButton onClick={handleEditClick} sx={{ color: "black" }}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>

            <Grid container pl={"20px"} spacing={"10px"} rowSpacing="20px">
              {owner_type !== "COMP" ? (
                <DataComp
                  value={`${first_name} ${last_name}\n${email}\n${mobile_no}\n${
                    pan_no ? "PAN: " + pan_no : ""
                  }`}
                  title={`Bike Owner`}
                />
              ) : (
                <DataComp
                  value={`${company_name}\n${email}\n${mobile_no}\n${
                    pan_no ? "PAN: " + pan_no : ""
                  }`}
                  title={`Bike Owner`}
                />
              )}
              {owner_type !== "COMP" && (
                <DataComp
                  value={`${gender === "M" ? "Male" : "Female"}, ${
                    is_married === "Y" ? "Married" : "Single"
                  } \n DOB: ${dob}\nOccupation: ${occupation_val}`}
                  title={`Personal Details`}
                />
              )}
              {nominee_name && (
                <DataComp
                  value={`${nominee_name}, ${nominee_age} \n ${nominee_relation}`}
                  title={`Nominee Details`}
                />
              )}
              <DataComp
                value={`${address}\n${city_name}, ${state_name} - ${pincode}`}
                title={`Communication Address`}
              />
              {FORMDATA?.is_reg_same_as_com === "N" && (
                <DataComp
                  value={`${reg_address}\n${reg_city_name}, ${reg_state_name} - ${reg_pincode}`}
                  title={`Communication Address`}
                />
              )}
            </Grid>
          </Paper>

          <Paper sx={paperStyle}>
            <Grid
              container
              pl={"20px"}
              spacing={"10px"}
              rowSpacing="20px"
              pt={3}
              pb={3}
            >
              <DataComp
                title="Your Bike"
                value={`${quote_data?.vehicle_make} ${quote_data?.vehicle_model} ${quote_data?.vehicle_variant}`}
              />
              <DataComp
                title="Registration Number"
                value={registration_number}
              />
              <DataComp title="Engine Number" value={engine_number} />
              <DataComp title="Chassis Number" value={chassis_number} />
              <DataComp
                title="Previous Policy Insurer"
                value={prev_insurer_name}
              />
              <DataComp title="Previous Policy Number" value={prev_policy_no} />
              {isOD && (
                <>
                  <DataComp
                    title="TP Policy Insurer"
                    value={tp_prev_insurer_name}
                  />
                  <DataComp
                    title="TP Policy Number"
                    value={tp_prev_policy_no}
                  />
                  <DataComp
                    title="TP Policy End Date"
                    value={tp_existing_exp_date}
                  />
                </>
              )}
              <DataComp
                title="Loan Provider"
                value={
                  is_financed === "N" ? "Bike Not on Loan" : financier_name
                }
              />
            </Grid>
          </Paper>

          <Paper sx={paperStyle}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label="I declare that the information provided above is true..."
            />
            {checked && (
              <StyledButton
                sx={{ mt: 2, mx: "auto", width: "60%" }}
                variant="contained"
                onClick={() => {
                  if (paymentData?.reset) {
                    submitProposal();
                  } else {
                    handlePayment(paymentData);
                  }
                }}
              >
                Proceed to Payment
              </StyledButton>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalSummary;
